<template>
  <div>
    <div v-if="lightBoxData.length > 0">
      <LightBox
        ref="lightbox"
        :media="lightBoxData"
        :show-thumbs="play"
        :length-to-load-more="lightBoxData.length"
        @wheel.prevent
        @touchmove.prevent
        @scroll.prevent
        @onClosed="onClosedEvent"
      />
    </div>
    <layout-vertical>
      <div v-if="!isGroupsFetched || getSelectedGroupStatus || isUserAdmin">
        <router-view />
      </div>
      <div v-else>
        <Error403 />
      </div>
      <template #navbar="{ toggleVerticalMenuActive }">
        <navbar :toggle-vertical-menu-active="toggleVerticalMenuActive" />

      </template>

    </layout-vertical>
  </div>
</template>

<script>
// import { $themeConfig } from '@themeConfig'

import LayoutVertical from '@core/layouts/layout-vertical/LayoutVertical.vue'
// import AppCustomizer from '@core/layouts/components/app-customizer/AppCustomizer.vue'
import LightBox from 'vue-it-bigger'
import Navbar from '../components/Navbar.vue'
import Error403 from '@/views/error/Error403.vue'

export default {
  components: {
    // AppCustomizer,
    Error403,
    LayoutVertical,
    Navbar,
    LightBox,
  },
  data() {
    return {
      // showCustomizer: $themeConfig.layout.customizer,
      play: false,
      selectedGroupId: null,
    }
  },
  computed: {
    lightBoxData() {
      return this.$store.getters['lightBox/getLightBoxData']
    },
    isSimmiUser() {
      return this.$store.getters['user/isSimmiUser']
    },
    isUserAdmin() {
      return this.$store.getters['user/isUserAdmin']
    },
    user() {
      return this.$store.getters['user/getUser']
    },
    getUserGroups() {
      return this.$store.getters['user/getAllUserGroups']
    },
    getSelectedGroupStatus() {
      return this.$store.getters['user/getSelectedGroupMemberStatus']
    },
    isGroupsFetched() {
      return this.$store.getters['user/isGroupsFetched']
    },
  },
  watch: {
    lightBoxData() {
      if (this.lightBoxData) {
        document.documentElement.style.overflow = 'hidden'
        return
      }
      document.documentElement.style.overflow = 'auto'
    },
  },
  async mounted() {
    if (this.$auth.isAuthenticated) {
      this.selectedGroupId = this.$route.query.groupId
      await this.$store.dispatch('packages/getPackagesAvailable')
      await this.$store.dispatch('user/getAllUserGroupsIfNotPresent', false)
      await this.setSelectedGroupIfNotSelected()
    }
  },
  methods: {
    hideLightBox() {
      this.$store.dispatch('lightBox/closeLightBox')
    },
    onClosedEvent() {
      this.hideLightBox()
    },
    setSelectedGroupIfNotSelected() {
      if (!this.getUserGroups.length && this.isSimmiUser) {
        this.$router.push('/error-403')
      }
    },
  },
}
</script>
